<template>
    <div>
      <PageTitleWithCountComponent title="Projects" :totalItemCount="totalProjects" class="show-in-bigger-mobile"/>
      <SearchInputComponent class="hide-in-bigger-mobile" @on-search="loadProjects($event)"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import PageTitleWithCountComponent from '@/core/components/common/header/PageTitleWithCountComponent.vue';
import SearchInputComponent from '@/core/components/common/header/SearchInputComponent.vue';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';

export default defineComponent({
  components: {
    PageTitleWithCountComponent,
    SearchInputComponent
  },
  data() {
    return {
      totalProjects: 0,
    };
  },
  // mounted() {
  //   this.loadProjects();
  // },
  methods: {
    ...mapActions(PROJECTS_STORE, ['getProjectPageList', 'setProjects']),
    ...mapActions(['setIsLoading']),

    async loadProjects(filters) {
      this.setIsLoading(true);
      await this.getProjectPageList(filters).then((response) => {
        const { data, totalCount } = response;
        this.totalProjects = totalCount;
        this.setProjects(data);
      })
        .finally(() => {
          this.setIsLoading(false);
        });
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
